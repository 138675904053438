import request from '@/utils/http';

/**
 * 用户列表
 * @param payload
 */
export function list(payload) {
    return request({ url: '/message/lists', method: 'post', data: payload })
}

/**
 * 更新用户
 * @param payload
 */
export function user_create(payload) {
    return request({ url: '/AdminUser/create', method: 'post', data: payload })
}

export function info(payload) {
    return request({ url: '/message/info/' + payload.id, method: 'post', data: payload })
}

/**
 * 删除
 * @param payload
 */
export function del(payload) {
    return request({ url: '/message/delete/' + payload.id, method: 'post', data: payload })
}