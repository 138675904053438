<template>
  <div class="main-body">
    <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
<a-form-item label="标题">
<a-input v-model:value="formState.title" class="medium-input" />
</a-form-item>
<a-form-item label="姓名">
<a-input v-model:value="formState.username" class="medium-input" />
</a-form-item>
<a-form-item label="Email">
<a-input v-model:value="formState.email" class="medium-input" />
</a-form-item>
<a-form-item label="qq">
<a-input v-model:value="formState.qq" class="medium-input" />
</a-form-item>
<a-form-item label="电话">
<a-input v-model:value="formState.phone" class="medium-input" />
</a-form-item>
<a-form-item label="时间">
<a-input v-model:value="formState.create_time" class="medium-input" />
</a-form-item>
<a-form-item label="内容">
<a-textarea v-model:value="formState.summary" style="width: 500px;height:200px"></a-textarea>
</a-form-item>
      <a-form-item :wrapper-col="{ span: 7, offset: 3 }">
        <a-button type="primary" @click="onSubmit">返回</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, reactive, onMounted } from "vue";
import * as Api from "@/views/message/api";
import { useRoute,useRouter } from "vue-router";

export default defineComponent({
  components: {},
  setup() {
    const formState = reactive({
      title: '',
summary: '',
username: '',
email: '',
qq: '',
phone: '',
create_time:''
    });
	
	const route = useRoute();
	const router = useRouter();

    const onSubmit = () => {
      router.push({
      path: "/message/list",
      query: {},
      });
    };
	
	
	const id = route.query.id;

    const getInitSetting = () => {
      Api.info({ id: id }).then((res) => {
formState.title = res.data.title;
formState.summary = res.data.summary;
formState.username = res.data.username;
formState.email = res.data.email;
formState.qq = res.data.qq;
formState.phone = res.data.phone;
formState.create_time = res.data.create_time;
      });
    };

    onMounted(() => {
      getInitSetting();
    });

    return {
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        span: 7,
      },
      formState,
      onSubmit,
    };
  },
});
</script>